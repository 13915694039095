@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import './Styles/authentification.module.scss';
@import './Styles/patients.module.scss';
@import './Styles/appointments.module.scss';
@import './Styles/statistiques.module.scss';

body {
    margin: 0;
    padding: 0;
    background-color: #F4F6F6 !important;
    color: #033446 !important;
}