.loginContainer {
    margin: 0;
    height: 100vh;

    .left {
        .center {
            height: 100vh;
            -webkit-justify-content: left;
            justify-content: left;
            padding-left: 100px;

        }
    }

    .right {
        .center {
            height: 100vh;
            -webkit-justify-content: left;
            justify-content: left;

            .box {
                position: 'relative' !important;
                min-height: 827px;
                width: 650px;
                margin-left: -50px;
                background: white;
                padding: 30px;
                box-shadow: 0px 5px 5px rgba(119, 141, 129, 0.1);
                border-radius: 16px;
            }
        }
        .customInput {
            &:hover {
                background: rgba(27, 187, 246, 0.1) !important;
                border: 1px solid #1BBBF6;
            }
        }
        .customSelectDate {
            width: 95px;
            &:hover {
                background: rgba(27, 187, 246, 0.1) !important;
                border: 1px solid #1BBBF6;
            }
        }
    }
}