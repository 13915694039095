.appointments {
    .box {
        background: white;
        padding: 30px;
        box-shadow: 0px 5px 5px rgba(119, 141, 129, 0.1);
        border-radius: 16px;
    }
    .cusTomTable {
        thead {
            tr {
                th {
                    border: none;
                    color: #CCD2E3;
                }
            }
        }
    
        tbody {
            tr {
                td {
                    border: none;
                    font-weight: bold;
                }
                &:hover {
                    background-color: #e5ebeb !important;
                }
            }
    
    
        }
    
        tbody tr:nth-of-type(odd) {
            background-color: #F4F5F5 !important;
            &:hover {
                background-color: #e5ebeb !important;
            }
        }
    }
    
}

.grayBox {
    background: #F4F5F5;
    padding: 10px 25px;
    border-radius: 16px;
    margin-bottom: 8px;
    width: 100%;
}

.rightIconFullWidth {
    .mantine-Button-inner {
        justify-content: left;
    }
}