.patients {
    .box {
        background: white;
        padding: 30px;
        box-shadow: 0px 5px 5px rgba(119, 141, 129, 0.1);
        border-radius: 16px;
    }
    .cusTomTable {
        thead {
            tr {
                th {
                    border: none;
                    color: #CCD2E3;
                }
            }
        }
    
        tbody {
            tr {
                td {
                    border: none;
                    font-weight: bold;
                }
                &:hover {
                    background-color: #e5ebeb !important;
                }
            }
    
    
        }
    
        tbody tr:nth-of-type(odd) {
            background-color: #F4F5F5 !important;
            &:hover {
                background-color: #e5ebeb !important;
            }
        }
    }
}

.mantine-Tabs-tabsListWrapper {
    border: none !important;
    .mantine-Tabs-tabLabel {
        font-weight: 600;
    }
}

.containerShow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

