.datePickerIcon {
    right: 0;
    left: auto;
    color: #033446;
}

.datePickerInput {
    border:none;
    background: transparent;
    font-weight: 700;
    font-size: 24px;
    color: #033446;
}